/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'

// Elements
import HeroDefault from 'components/elements/HeroDefault'
import MoodImage from 'components/elements/MoodImage'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ContentDefault from 'components/elements/ContentDefault'
import ButtonDefault from 'components/elements/ButtonDefault'

const Form = styled.div`
  background-color: ${props => props.theme.color.text.secondary};
  border-radius: 15px;
`

const Title = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.xm};
  color: ${props =>
    props.light
      ? props.theme.color.text.light
      : props.theme.color.text.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }

        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        streetname
        housenumber
        zipcode
        city
        emailAddress: email_address
        phonenumber
        whatsAppNumber: whatsapp_number
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, content, path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout headerTop>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroDefault title={title} image={acf.backgroundImage} />
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-7 pl-lg-5">
            <TitleSub>Contact opnemen met</TitleSub>
            <BrandTitle />
            <div className="mt-5">
              <ContentDefault content={content} />
            </div>
            <div className="mt-5">
              <Title>Adres</Title>
              <div className="mt-2">
                {acf.streetname} 
                {' '}
                {acf.housenumber}
              </div>
              <div className="mt-1">
                {acf.zipcode} 
                {' '}
                {acf.city}
              </div>
              <ContentDefault
                content={`<div class="mt-4">
                  <a href="mailto:${acf.emailAddress}">
                    ${acf.emailAddress}
                  </a>
                </div>
                <div class="mt-1">
                  <a href="tel:${acf.phonenumber}">
                    ${acf.phonenumber}
                  </a>
                </div>`}
              />
              <div className="mt-4">
                <ButtonDefault
                  whatsApp
                  to={`whatsapp://send?abid=${acf.whatsAppNumber}&text=Hoi, ik heb een vraag.`}
                  isAnchor
                >
                  {acf.whatsAppNumber}
                </ButtonDefault>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0">
            <Form>
              <GravityForm id={1} />
            </Form>
          </div>
        </div>
      </div>
      {acf.moodImage && (
        <MoodImage image={acf.moodImage} />
      )}
    </Layout>
  )
}

export default PageTemplate
